<template>
  <el-dialog
    title="申诉内容"
    :visible.sync="$_visible"
    width="500px">
    <span>任务确认完成后，任务发布方将会验证任务完成程度，并根据完成程度发放奖励！</span>
    <span slot="footer">
      <el-button
        size="small"
        @click="closeDialog">
        取消
      </el-button>
      <el-button
        size="small"
        type="primary"
        :loading="btnLoading"
        @click="confirmCompleteTask">
        确认完成
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    taskingId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      btnLoading: false
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    taskId: {
      get () {
        return this.$route.params.taskId
      }
    }
  },
  methods: {
    // 确认任务完成
    confirmCompleteTask () {
      this.btnLoading = true
      api.confirmCommit(this.taskingId).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.closeDialog()
          this.$router.push('/user/task-manager')
        } else {
          this.$message.error('操作失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      }).finally(() => {
        this.btnLoading = false
      })
    },
    // 关闭dialog
    closeDialog () {
      this.$_visible = false
    }
  }
}
</script>
