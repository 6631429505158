<template>
  <div class="main_container">
    <div class="task_detail_container">
      <!-- 详情页头部 -->
      <detail-title
        :backToPath="'/user/task-manager'"
        :descriptionList="descriptionList"
      ></detail-title>

      <!-- 详情信息列表 -->
      <task-description
        :isUpload="true"
        :descriptionList="descriptionList"
        ref="detail_upload">
      </task-description>

      <!-- 确认完成按钮 -->
      <div class="task_submit">
        <el-button
          v-if="curStatus !==2"
          size="small"
          type="primary"
          @click="confirmTask" >
          确认完成
        </el-button>
        <el-button
          v-if="curStatus !==2"
          size="small"
          type="danger"
          @click="abandonTaskVisible = true"
          plain>
          放弃任务
        </el-button>
        <el-button
          v-if="status === 7"
          size="small"
          type="danger"
          @click="serviceTaskVisible = true"
          plain>
          申请客服介入
        </el-button>
      </div>
    </div>

    <!-- 任务相关信息 -->
    <task-related-info></task-related-info>

    <!-- 确认完成任务对话框 -->
    <confirm-task-dialog
      :visible.sync="confirmTaskVisible"
      :taskingId="taskingId">
    </confirm-task-dialog>

    <!-- 放弃任务对话框 -->
    <abandon-task-dialog
      :visible.sync="abandonTaskVisible">
    </abandon-task-dialog>

    <service-dialog
      :visible.sync="serviceTaskVisible">
    </service-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import TaskRelatedInfo from '@/components/task-detail/related-info/index.vue'
import DetailTitle from '@/components/task-detail/title/index.vue'
import TaskDescription from '@/components/task-detail/description/index.vue'
import ConfirmTaskDialog from '../components/confirm-task-dialog.vue'
import AbandonTaskDialog from '../components/abandon-task-dialog.vue'
import ServiceDialog from '../components/service-dialog.vue'

export default {
  components: {
    TaskRelatedInfo,
    DetailTitle,
    TaskDescription,
    ConfirmTaskDialog,
    AbandonTaskDialog,
    ServiceDialog
  },
  data () {
    return {
      confirmTaskVisible: false,
      abandonTaskVisible: false,
      serviceTaskVisible: false,
      curStatus: 0,
      status: 0,
      descriptionList: {},
      taskingId: ''
    }
  },
  computed: {
    taskId () {
      return this.$route.params.taskId
    }
  },
  created () {
    this.getTaskDetail()
  },
  methods: {
    // 获取任务详情
    getTaskDetail () {
      api.taskDetail(this.taskId).then(res => {
        if (res.data.code === 0) {
          const data = res.data.data
          const { curStatus, status, userAttachment, taskingId } = data
          this.curStatus = curStatus
          this.status = status
          data.fileList = []
          this.taskingId = taskingId
          // 如果附件存在的话
          if (userAttachment) {
            data.fileList.push({
              uid: userAttachment.id,
              name: userAttachment.originName,
              url: userAttachment.downloadUrl
            })
          }
          this.descriptionList = data
        } else {
          this.$message.error('请求出错')
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求异常')
      })
    },
    confirmTask () {
      if (this.descriptionList.fileList.length === 0) {
        this.$message.error('请上传完结附件')
      } else {
        this.confirmTaskVisible = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main_container {
  width: 100%;
  height: 100%;
  display: flex;
}
.task_detail_container {
  min-width: 1200px;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  .task_submit {
    height: 80px;
    padding: 0 30px;
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
  }
}
</style>
