<template>
  <el-dialog
    title="申诉内容"
    :visible.sync="$_visible"
    width="500px"
  >
    <el-input
      type="textarea"
      :autosize="{minRows: 5}"
      placeholder="请输入内容"
      v-model="serviceContent">
    </el-input>
    <span
      slot="footer"
      class="dialog_footer">
      <el-button
        size="small"
        @click="closeDialog">
        取消
      </el-button>
      <el-button
        size="small"
        type="primary"
        :loading="btnLoading"
        @click="commitService">
        提交申诉
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      btnLoading: false,
      serviceContent: ''
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    taskId: {
      get () {
        return this.$route.params.taskId
      }
    }
  },
  methods: {
    commitService () {
      if (this.serviceContent === '' || this.serviceContent === null) {
        this.$message.error('请填写申诉内容')
        return
      }
      this.btnLoading = true
      api.userAppeal(this.taskId, {
        appealContent: this.serviceContent
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.closeDialog()
          this.$router.push('/user/task-manager')
        } else {
          this.$message.error('客服正在处理中，请不要重复提交')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      }).finally(() => {
        this.btnLoading = false
      })
    },
    // 关闭dialog
    closeDialog () {
      this.$_visible = false
    }
  }
}
</script>
